/************
Description: tag collection type pages like /projects/augmented-reality/


************/
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import PageHero from '../components/PageHero';
import ContentSection from '../components/ContentSection';

const Page = ({
  data: {
    page
  },
}) => (
  <Layout className={"defaultPage"} title={page.data.meta_title} desc={page.data.meta_description} img={page.data.image} social={page.data.social_body}>
    {page.data.body.map((slice, idx) => {
      switch (slice.__typename) {
        case 'PrismicPageBodyHero':
          return <PageHero key={slice.id} data={slice.primary} />;
        case 'PrismicPageBodyFeature':
          // return <HomepageFeature key={slice.id} data={slice.primary} />
          return null;
        case 'PrismicPageBodyFeatureSection':
          return <HomepageFeatureSection key={slice.id} data={slice.primary} />
        case "PrismicPageBodyContentSection":
          return <ContentSection  key={slice.id} data={slice} />
        default:
          return <div key={idx}>unknown slice</div>
      }
    })}
  </Layout>
);

export default Page;

Page.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      id: PropTypes.string,
      uid: PropTypes.string,
      data: PropTypes.object
    })
  }).isRequired,
};

export const pageQuery = graphql`
  query PageQuery($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      id
      uid
      data {
        page_title {
          html
        }
        page_description {
          html
        }
        meta_title
        meta_description
        image {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
          alt
          url
        }
        social_body {
          __typename
          ... on PrismicPageSocialBodyTwitterCard {
            id
            primary {
              card_type
              title {
                html
                text
              }
              twitter_handle
              image {
                localFile {
                  childImageSharp {
                    fixed {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                alt
              }
            }
          }
          ... on PrismicPageSocialBodyGeneralCard {
            id
            primary {
              title {
                html
                text
              }
              description {
                html
                text
              }
              image {

                alt
                url
              }
            }
          }
        }
        body {
          __typename
          ... on PrismicPageBodyHero {
            id
            primary {
              hero_title {
                html
                text
              }
              hero_body {
                html
              }
              background_image {
                localFile {
                  childImageSharp {
                    fluid (maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyContentSection {
            id
            primary {
              fragment_id
              heading {
                html
                text
              }
              sub_heading {
                html
                text
              }
              section_copy {
                html
                text
              }
              media_alignment
              fragment_id
              box_colour
              background_image {
								alt
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                    fixed {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyFeature {
            id
            primary {
              heading {
                text
              }
              featured_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                url
              }
              project_title {
                html
              }
              project_title {
                text
              }
              project_description {
                html
              }
              featured_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                url
              }
              section_link_label
              section_link
            }
          }
          ... on PrismicPageBodyFeatureSection {
            id
            primary {
              banner_title {
                html
                text
              }
              banner_link
              banner_link_label
              project_url
              project_title {
                html
                text
              }
              project_subtitle {
                html
                text
              }
              feature_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`;
