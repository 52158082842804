import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import BackgroundImage from 'gatsby-background-image';

const WrapperHero = styled.div`
  /* background: ${props => props.theme.colors.white}; */
  color: ${props => props.theme.colors.greyDark};
  height: auto;
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    &, .hero{
      height:Calc(100vh - 124px);
    }
  }
  a {
    color: ${props => props.theme.colors.greyDark};
    &:hover {
      color: ${props => props.theme.colors.primaryLight};
    }
  }
  .hero {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: ${props => props.theme.breakpoint.l}) {
      &:before, &:after{
        /* opacity:0.1 !important; */
        background-position:50% center;
      }
    }
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      &:before, &:after{
        /* opacity:0.1 !important; */
        background-position:45% center;
      }
    }
  }
`;

var linkResolver = function (doc) {
  // URL for a category type
  if (doc.type == 'category') {
    return '/category/' + doc.uid;
  }
  // URL for a product type
  if (doc.type == 'product') {
    return '/product/' + doc.uid;
  }
  // Backup for all other types
  return '/';
}

const HeroContent = styled.div`
  align-items: left;
  width: 58.8%;
  padding-right:0;
  h1{
    ${props => props.theme.font.h3};
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      ${props => props.theme.font.h1.s};
    }
    margin-bottom:1rem;
  }

  p {
      ${props => props.theme.font.subtitle};
      @media (max-width: ${props => props.theme.breakpoint.l}) {
        ${props => props.theme.font.subtitle.l};
      }
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        ${props => props.theme.font.subtitle.s};
      }
  }
  @media (min-width: ${props => props.theme.breakpoint.l}) {
    min-height:83vh;
    margin-top:8rem;
    margin-top:0;
    padding-top:30vh !important;
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    width: 66.6%;
    min-height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.defaultModulePadding{
      padding-top:0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    width: 69%;
    min-height:Calc(100vh - 1.66rem);
  }
`;

const StyledLink = styled(Link)`
  transform: translateX(0);
  transition: all 200ms ease-out;
  padding: 1rem;
  &:hover {
    color: ${props => props.theme.colors.bg};
    transform: translateX(-6px);
  }
`;

const PageHero = ({ data }) => {
  return (
    <WrapperHero className='component_hero'>
      <BackgroundImage fluid={data.background_image.localFile.childImageSharp.fluid} className='hero'>
        <HeroContent className="defaultModulePadding">
          {data.hero_title.text && /*text will be empty if the html tag is empty*/
            <div>
            { ReactHtmlParser(data.hero_title.html) }
            </div>
          }
          <div>
            { ReactHtmlParser(data.hero_body.html) }
          </div>
        </HeroContent>
      </BackgroundImage>
    </WrapperHero>
  )
}

export default PageHero;

PageHero.propTypes = {
  data: PropTypes.object.isRequired
};
